import {
  DownloadAttachmentRequestAction,
  DOWNLOAD_ATTACHMENT_REQUEST,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  DownloadAttachmentsRequestAction,
  AttachmentModel,
  AttachmentValidation,
  GET_ATTACHMENT_VALIDATION_REQUEST
} from "./types";
import { API_ROUTE } from "common/constants";
import { MEDIA_ENDPOINT } from "common/endpoints";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  downloadAttachmentFailure,
  downloadAttachmentsFailure,
  getAttachmentValidationSuccess
} from "./actions";
import axios from "axios";

const downloadAttachmentApi = (
  applicationId: number,
  attachmentReference: string,
  isMasterAttachment: boolean,
  filename: string
) => {
  return axios.get(
    `${API_ROUTE}${MEDIA_ENDPOINT}/attachments/attachment/${applicationId}/${attachmentReference}/${isMasterAttachment}/${filename}`,
    { responseType: "arraybuffer" }
  );
};

function* downloadAttachment(action: DownloadAttachmentRequestAction) {
  try {
    const { data }: { data: any } = yield call(
      downloadAttachmentApi,
      action.applicationId,
      action.attachmentReference,
      action.isMasterAttachment,
      action.filename
    );

    download(data, action.filename);
  } catch (e) {
    yield put(downloadAttachmentFailure("Failed to download the attachment."));
  }
}

export function* watchDownloadAttachmentRequest() {
  yield takeLatest(DOWNLOAD_ATTACHMENT_REQUEST, downloadAttachment);
}

const downloadAttachmentsApi = (
  applicationId: number,
  attachments: AttachmentModel[]
) => {
  return axios.post(
    `${API_ROUTE}${MEDIA_ENDPOINT}/attachments/attachments`,
    {
      attachments: attachments,
      applicationId: applicationId
    },
    {
      responseType: "blob"
    }
  );
};

function* downloadAttachments(action: DownloadAttachmentsRequestAction) {
  try {
    const { data }: { data: any } = yield call(
      downloadAttachmentsApi,
      action.applicationId,
      action.attachments
    );

    const newDate = new Date();
    const dateString = `${newDate.getFullYear()}-${newDate.getMonth() +
      1}-${newDate.getDate()}_${newDate.getHours()}-${newDate.getMinutes()}-${newDate.getSeconds()}`;

    download(data, `${action.applicationNumber}_${dateString}.zip`);
  } catch (e) {
    yield put(
      downloadAttachmentsFailure("Failed to download the attachments.")
    );
  }
}

const download = (data: any, name: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export function* watchDownloadAttachmentsRequest() {
  yield takeLatest(DOWNLOAD_ATTACHMENTS_REQUEST, downloadAttachments);
}

const attachmentValidationApi = () => {
  return axios.get(`${API_ROUTE}${MEDIA_ENDPOINT}/attachmentvalidation`);
};

function* attachmentValidation() {
  const { data }: { data: AttachmentValidation } = yield call(
    attachmentValidationApi
  );
  yield put(getAttachmentValidationSuccess(data));
}

export function* watchGetAttachmentValidation() {
  yield takeLatest(GET_ATTACHMENT_VALIDATION_REQUEST, attachmentValidation);
}
